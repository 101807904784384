import styles from '../../styles/features/videoContainer.module.css'

function VideoContainer({}) {
  return (
    <div className={styles.main}>
      <video 
        src={"https://shorecastsandroidscreenrecording.s3.amazonaws.com/Screen_Recording_20240729_172917_shore_fe_mobile.mp4"}
        autoPlay 
        loop 
        muted
        playsInline
        className={styles.vid}
      />
    </div>
  )
}
export default VideoContainer