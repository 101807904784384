import styles from '../../styles/screens/terms.module.css'
import Footer from '../features/footer'
import Header from '../features/header'
import PrivacyContainer from '../features/privacyContainer'

function Privacy({}) {
  return (
    <div className={styles.main}>
      <Header/>
      <PrivacyContainer/>
      <Footer/>
    </div>
  )
}
export default Privacy