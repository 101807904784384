import styles from '../../styles/features/feature.module.css'
import React from 'react'

interface Props {
  title: string,
  description: string,
  presser: any, 
  collapseAll: boolean,
  isSelected: boolean,
}

const Feature: React.FC<Props> = ({
  title,
  description,
  presser,
  collapseAll,
  isSelected,
}) => {
  const press = () => {
    presser(title)
  }
  return (
    <div className={styles.main}>
      {
        (isSelected  || collapseAll) ?
        <text className={isSelected ? styles.selectedFeature : styles.feature} onClick={()=>press()}>
          {title}
        </text>
        : null
      }
      {
        isSelected ? 
        <text className={styles.description}>
          {description}
        </text>
        : null
      }
    </div>
  )
}
export default Feature