import styles from '../../styles/features/featureContainer.module.css'
import Feature from './feature'
import { useState } from 'react' 
import featureArray from '../helpers/featureArray'
import FeatureType from '../../types/FeatureType'
function FeatureContainer({}) {
  const [selected, setSelected] = useState("")
  const presser = (title: string) => {
    if(selected !== title) {
      setSelected(title)
    } else {
      setSelected("")
    }
  }
  const collapseAll: boolean = selected === ""
  const topFeatures: FeatureType[] = featureArray.slice(0, 4)
  const bottomFeatures: FeatureType[] = featureArray.slice(4, 8)
  console.log("HERRRO")

  return (
    <div className={styles.main}>
      <div className={styles.section}>
        {
          collapseAll ? 
          <text className={styles.title}>
            Digital Guide for Local Fishing Information
          </text>
          : null
        }
        <div className={styles.list}>
          {
            topFeatures.map((feature, index) => (
              <Feature 
                title={feature.title}
                description={feature.description}
                presser={presser}
                collapseAll={collapseAll}
                isSelected={selected === feature.title}
              />
            ))
          }
        </div>
      </div>
      <div className={styles.section}>
        {
          collapseAll ? 
          <text className={styles.title}>
            Plan Your Trips by Viewing
          </text> 
          : null
        }
        <div className={styles.list}>
        {
          bottomFeatures.map((feature, index) => (
            <Feature 
              title={feature.title}
              description={feature.description}
              presser={presser}
              collapseAll={collapseAll}
              isSelected={selected === feature.title}
            />
          ))
        }
        </div>
      </div>
    </div>
  )
}
export default FeatureContainer
