import FeatureType from "../../types/FeatureType"

const featureArray: FeatureType[] = [
  {
    title: "-Tidal Predictions",
    description: "Real-time tidal predictions for today and tomorrow, including tide charts, high/low tide times, and tide heights (ft) at any given time of day. Powered by over 1100 NOAA observation stations. "
  },
  {
    title: "-Current Water Temperature",
    description: "Current water temperature at the selected tidal station. Real-time data retrieved from NOAA observation stations. Water and/or air temperatures are not available for all stations."
  },
  {
    title: "-Daily Weather Forecasts",
    description: "Weather and wind predictions (AM & PM) for the next 10 days, for any location in the United States. Includes lunar phase, sunrise/sunset times, and first/last light times. Real-time National Weather Service data retrieved using latitude and longitude from the center of the ShoreMap."
  },
  {
    title: '-Hourly Weather Predictions',
    description: 'Weather and wind predictions by the hour for the next 72 hours, including summaries for each hour. Real-time National Weather Service data retrieved using latitude and longitude from the ShoreMap center or selected station.'
  },
  {
    title: '-Stations',
    description: 'Tidal predictions and water temperature data retrieved from NOAA stations. Represented on the ShoreMap by blue markers.'
  },
  {
    title: '-Tackle Shops',
    description: 'Data derived from registered entities in the US and groomed by the engineering team here at ShoreCasts. Represented on the ShoreMap by yellow markers.'
  },
  {
    title: '-Marinas',
    description: 'Data derived from registered entities in the US and groomed by the engineering team here at ShoreCasts. Represented on the ShoreMap by pink markers'
  },
  {
    title: '-Boat Ramps',
    description: 'Data derived from registered entities in the US and groomed by the engineering team here at ShoreCasts. Represented on the ShoreMap by green markers.'
  },
]

export default featureArray