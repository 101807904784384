import { useEffect, useState } from 'react'
import styles from '../../styles/screens/splash.module.css'
import FeatureContainer from '../features/featureContainer'
import Footer from '../features/footer'
import Header from '../features/header'
import TitleContainer from '../features/titleContainer'
import VideoContainer from '../features/videoContainer'
import useWindowDimensions from '../helpers/windowDimensions'
function Splash() {
  const [landscape, setLandscape] = useState<boolean>(true)
  const {height, width} = useWindowDimensions()
  useEffect(() => {
    if((width < 786 && landscape) || (width < 834 && height > 1080 && landscape)) {
      setLandscape(false)
    } 
    else if((width >= 786 && !landscape) ) {
      setLandscape(true)
    }
  }, [width])
  return (
    <div className={landscape ? styles.main : styles.portraitMain}>
      <Header/>
      {
        landscape ?
        <div className={styles.middleSection}>
          <div className={styles.leftSection}>
            <FeatureContainer/>
            <TitleContainer/>
          </div>
          <div className={styles.rightSection}>
            <VideoContainer/>
          </div>
        </div>
        : 
        <div className={styles.middlePortraitSection}>
            <FeatureContainer/>
            <VideoContainer/>
            <TitleContainer/>
        </div>
      }
      <Footer/>
    </div>
  )
}
export default Splash