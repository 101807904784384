import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Splash from './components/screens/splash';
import Privacy from './components/screens/privacy';
import Terms from './components/screens/terms';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Splash/>}/>
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
