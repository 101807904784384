import styles from '../../styles/features/footer.module.css'
import shrimpFly from '../../assets/shrimpFly.png'
import { useNavigate } from 'react-router-dom'
function Footer({}) {
  const navigate = useNavigate()
  return (
    <div className={styles.main}>
      <div className={styles.logoContainer}>
        <img src={shrimpFly} className={styles.logo}/>
        <text onClick={()=>navigate("/")} className={styles.logoText}>
          Shrimp Republic Studios
        </text>
      </div>
      <div className={styles.linksContainer}>
        <text className={styles.navLink} onClick={()=>navigate('/terms')}>
          Terms of Use
        </text>
        <text className={styles.navLink} onClick={(()=>navigate('/privacy'))}>
          Privacy Policy
        </text>
        <text className={styles.email}>
          support@shorecasts.com
        </text>
      </div>
    </div>
  )
}
export default Footer